<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <v-row>
        <v-col md="3" class="mr-2" v-if="returnAbility('educational-year:store')">
          <v-dialog v-model="newEduYearDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" color="green" class="white--text">
                <v-icon size="20">mdi-plus</v-icon>
                {{ $t('add year study') }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="d-flex justify-center card-title__heading">
                <h2> {{ $t('add year study') }}</h2>
              </v-card-title>
              <v-card-text>
                <v-text-field :label="$t('name')" v-model="newEduYear.name" outlined dense color="#757575"
                  type="text"></v-text-field>
                <v-text-field :label="$t('begin')" v-model="newEduYear.start" outlined dense color="#757575"
                  type="text"></v-text-field>
                <v-text-field :label="$t('end')" v-model="newEduYear.end" outlined dense color="#757575"
                  type="text"></v-text-field>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn color="green" class="white--text" @click="submitNewEduYear" :loading="submitBtnLoading"
                  :disabled="disableSubmitBtn">{{ $t('add') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col md="3" class="d-flex justify-center align-center">
          <v-text-field v-model="nameSearch" :label="$t('search by name')" dense outlined hide-details></v-text-field>
          <v-icon class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
        </v-col>
      </v-row>
      <app-base-table :tableOptions="tableOptions" :enableDelete="displayDeleteIcon" serverRoute="/educational-year"
        @re-fetch-data="fetchData" @re-fetch-paginated-data="fetchData($event)">
        <template slot="editFeature" slot-scope="{ row }">
          <app-edit-educational-year-modal v-if="returnAbility('educational-year:update')" @recordUpdated="fetchData"
            :recordData="row"></app-edit-educational-year-modal>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import editEducationalYearModal from "./editEducationalYearModal.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    appEditEducationalYearModal: editEducationalYearModal,
  },
  data() {
    return {
      nameSearch: "",
      submitBtnLoading: false,
      newEduYear: {
        name: "",
        start: "",
        end: "",
      },
      newEduYearDialog: false,
    };
  },
  methods: {
    ...mapActions({
      fetchEduYears: "educationalYear/fetchEducationalYears",
    }),
    fetchData(paginationValue) {
      this.fetchEduYears({
        paginationValue,
        nameSearch: this.nameSearch,
      });
    },
    async submitNewEduYear() {
      try {
        this.submitBtnLoading = true;
        const response = await axios.post("/educational-year", this.newEduYear);
        this.$Notifications(
          this.$t('add success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.fetchData(1);
        this.newEduYearDialog = false;
      } catch (err) {
      } finally {
        this.submitBtnLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      tableOptions: "educationalYear/getTableOptions"
    }),
    disableSubmitBtn() {
      if (
        !this.newEduYear.name ||
        !this.newEduYear.start ||
        !this.newEduYear.end
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    if (this.returnAbility("educational-year:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style>

</style>