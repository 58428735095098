<template>
  <v-dialog v-model="editDialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center">
        <h2>{{ $t('edit') }} {{ recordData.name }}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field :label="$t('name')" v-model="editEduYear.name" outlined dense color="#757575"
          type="text"></v-text-field>
        <v-text-field :label="$t('begin')" v-model="editEduYear.start" outlined dense color="#757575"
          type="text"></v-text-field>
        <v-text-field :label="$t('end')" v-model="editEduYear.end" outlined dense color="#757575"
          type="text"></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="green" class="white--text" depressed @click="editRecord" :loading="editBtnLoading"
          :disabled="disableEditBtn">{{ $t('edit') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      editEduYear: {
        name: "",
        start: "",
        end: "",
      },
      editBtnLoading: false,
      editDialog: false,
    };
  },
  props: {
    recordData: Object,
  },
  watch: {
    editDialog() {
      this.editEduYear.name = this.recordData.name;
      this.editEduYear.start = this.recordData.start;
      this.editEduYear.end = this.recordData.end;
    },
  },
  computed: {
    disableEditBtn() {
      if (
        !this.editEduYear.name ||
        !this.editEduYear.start ||
        !this.editEduYear.end
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async editRecord() {
      try {
        this.editBtnLoading = true;
        await axios.put(
          `/educational-year/${this.recordData.id}`,
          this.editEduYear
        );
        this.$Notifications(
          this.$t('add success'),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("recordUpdated");
        this.editDialog = false;
      } catch (err) {
      } finally {
        this.editBtnLoading = false;
      }
    },
  },
  created() {
    this.editEduYear.name = this.recordData.name;
    this.editEduYear.start = this.recordData.start;
    this.editEduYear.end = this.recordData.end;
  },
};
</script>

<style>

</style>